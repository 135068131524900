/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiHost = 'https://api.dataauditor.pro/v1/';
export const apiRoot = 'https://api.dataauditor.pro/';
// export const apiHost = 'http://23.227.167.2:3039/api/v1/';
// export const apiHost = "http://172.25.176.1:3038/v1/";
// export const apiRoot = "http://172.25.176.1:3038/";
export const customerCheckerApi = createApi({
    reducerPath: 'customerCheckerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: apiHost,
        prepareHeaders: headers => headers,
    }),
    // tagTypes: ['Insurance', 'deliveryAddress', 'familyMembers'],
    endpoints: builder => ({
        registerUser: builder.mutation({
            query: inputData => ({
                method: 'POST',
                url: 'auth/register',
                body: inputData,
            }),
        }),
        loginUser: builder.mutation({
            query: inputData => ({
                method: 'POST',
                url: 'auth/login',
                body: inputData,
            }),
        }),
        updateUser: builder.mutation({
            query: ({ user_id, token, formData }) => ({
                method: 'PUT',
                url: `users/${  user_id}`,
                headers: {
                    Authorization: `Bearer ${  token}`,
                },
                body: formData,
            }),
        }),
        ResetRequest: builder.mutation({
            query: (body) => ({
                    method: 'POST',
                    url: `auth/reset`,
                    body,
                }),
            // invalidatesTags: ['Orders'],
        }),
        CompleteReset: builder.mutation({
            query: (body) => ({
                    method: 'PUT',
                    url: `auth/reset`,
                    body,
                }),
        }),
        VerifyToken: builder.mutation({
            query: (body) => ({
                    method: 'PUT',
                    url: `auth/verify/${body}`,
                }),
        }),
        getUserMetaByKey: builder.query({
            query: ({ token, meta_key }) => ({
                method: 'GET',
                url: `usermeta?meta_key=${meta_key}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        getUserJobs: builder.query({
            query: (token) => ({
                method: 'GET',
                url: `job_logs`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
        updateUserMeta: builder.mutation({
            query: ({ meta_id, token, formData }) => ({
                method: 'PUT',
                url: `usermeta/${meta_id}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
        UploadStripeCustomers: builder.mutation({
            query: ({ token, formData }) => ({
                method: 'POST',
                url: `stripe`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }),
        }),
    }),
});

export const {
    useResetRequestMutation,
    useCompleteResetMutation,
    useVerifyTokenMutation,
    useLoginUserMutation,
    useUpdateUserMutation,
    useRegisterUserMutation,
    useGetUserMetaByKeyQuery,
    useUpdateUserMetaMutation,
    useUploadStripeCustomersMutation,
    useGetUserJobsQuery,
} = customerCheckerApi;
