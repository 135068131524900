import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {Button, Typography, Stack} from '@mui/material';
import { useSelector } from 'react-redux';

// components
import { useUploadStripeCustomersMutation } from '../../../redux/services/customerChecker';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export default function UploadCustomerData() {
  const [ customerData, setCustomerData ] = useState(null);
  const userData = useSelector(state => state.user);
  const [UploadStripeCustomers] = useUploadStripeCustomersMutation();
  const handleUploadCustomers = () => {
    if(!customerData) {
      alert(`You must choose a file to submit`);
      return;
    }
    const formData = new FormData();
    formData.append("customerList", customerData);
    UploadStripeCustomers({token: userData.token, formData}).unwrap()
    .then(upResponse => {
      if (upResponse.status === 'success') {
        alert('Awesome! Your list has been sent to check. you can check status from Jobs page and download');
      }
    })
    .catch(upErr => {
      console.log(`upErr`, upErr);
    })
  }
  return (
    <>
      <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Upload Stripe customer list
        </Typography>
        <Button component="label" variant="outlined" startIcon={<Iconify icon="line-md:uploading-loop" />}>
          Upload Customer Data
          <VisuallyHiddenInput type="file" onChange={(ev) => {
            setCustomerData(ev.target.files[0]);
          }}/>
        </Button>
      </Stack>
      <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5}>
        <Button 
          component="label" 
          variant="contained" 
          startIcon={<Iconify icon="teenyicons:send-outline" />} 
          onClick={handleUploadCustomers}
        >
          Submit
        </Button>
      </Stack>
      
    </>
  );
}
