/* eslint-disable import/no-named-as-default */
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import { customerCheckerApi } from './services/customerChecker'
import userSlice from './slices/userSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [customerCheckerApi.reducerPath],
}

const rootReducers = combineReducers({
    [customerCheckerApi.reducerPath]: customerCheckerApi.reducer,
    user: userSlice,
})

const _persistedReducer = persistReducer(persistConfig, rootReducers);
export const store = configureStore({
    reducer: _persistedReducer,
    middleware: [thunk, customerCheckerApi.middleware],
    devTools: false
});
export const persistor = persistStore(store)
