import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { updateUser } from '../../../redux/slices/userSlice';
import { useLoginUserMutation } from '../../../redux/services/customerChecker';

import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  // const userState = useSelector(state => state.user);
  // console.log(`savedUser`, userState);
  const [showPassword, setShowPassword] = useState(false);
  // const [ errorMessage, setErrorMessage ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ email, setEmail ] = useState('');

  // useEffect(() => {
  //   if (userState.token) {
  //     navigate('/dashboard', { replace: true });
  //     // return 0;
  //   }
  // }, [navigate, userState]);

  const handleLogin = () => {
    if(!email.length || !password.length) {
      // setErrorMessage('Email Or Password must be filled out!');
      alert('Email and password must be filled out!')
      return;
    }
    loginUser({email, password}).unwrap()
    .then(response => {
      const payload = { ...response.user, token: response.tokens.access.token}
      console.log(`payload`, payload);
      dispatch(updateUser(payload))
      navigate('/dashboard', { replace: true });

    })
    .catch(errLog => console.log(`errResponse`, errLog))

  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(ev) => setEmail(ev.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(ev) => setPassword(ev.target.value) }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
    </>
  );
}
