import { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import {  useSelector } from 'react-redux';
// @mui
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useGetUserMetaByKeyQuery, useUpdateUserMetaMutation } from '../../../redux/services/customerChecker';


export default function SettingsForm() {
    const [ updateUserMeta ] = useUpdateUserMetaMutation();
    const userData = useSelector(state => state.user);
    const { data : stripeData, isSuccess } = useGetUserMetaByKeyQuery({ token: userData.token, meta_key: 'stripe_secret' })

    const [stripeSecret, setStripeSecret] = useState('');

    useEffect(() => {
        if(isSuccess) {
            setStripeSecret(stripeData.value);
        }
    }, [isSuccess, stripeData])

    const updateStripeSetting = () => {
        if (!stripeSecret.length) {
            alert(`Please fill out all the fields properly`);
            return;
        }

        const payload = {
            meta_id: stripeData.id,
            token: userData.token,
            formData: {
                value: stripeSecret,
                key: 'stripe_secret',
            },
        }
        updateUserMeta(payload).unwrap()
            .then(regResponse => {
                // console.log(`regResponse`, regResponse);
                if (regResponse.value) {
                    alert('Setting has been updated successfully!')
                }
            })
            .catch(regErrResponse => {
                // console.log(`regErrResponse`, regErrResponse);
                if (regErrResponse.data) {
                    alert(regErrResponse.data.message);
                }
            })
        // navigate('/dashboard', { replace: true });
    };

    return (
        <>
            <Stack spacing={3}>
                <TextField name="stripe_secret" label="Stripe Secret Key"  value={stripeSecret} onChange={(ev) => setStripeSecret(ev.target.value)} />
            </Stack>
            <br />
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={updateStripeSetting}>
                Update Settings
            </LoadingButton>
        </>
    );
}
