import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useRegisterUserMutation } from '../../../redux/services/customerChecker';

import Iconify from '../../../components/iconify';

export default function RegistrationForm() {
  const navigate = useNavigate();
  const [registerUser] = useRegisterUserMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPass, setRepeatPass] = useState('');

  const handleRegistration = () => {
    if(!email.length || !name.length || !password.length) {
      alert(`Please fill out all the fields properly`);
      return;
    }

    if(password !== repeatPass) {
      alert(`Your Password and repeat password doesn't match, please check!`);
    }
    const payload = {
      email,
      password,
      name,
    }
    registerUser(payload).unwrap()
    .then(regResponse => {
      console.log(`regResponse`, regResponse);
      if (regResponse.user) navigate('/login', { replace: true })
    })
    .catch(regErrResponse => {
      console.log(`regErrResponse`, regErrResponse);
      if(regErrResponse.data) {
        alert(regErrResponse.data.message);
      }
    })
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="name" label="Full Name" value={name} onChange={(ev) => setName(ev.target.value)}/>
        <TextField name="email" label="Email address" value={email} onChange={(ev) => setEmail(ev.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password} 
          onChange={(ev) => setPassword(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name="repeatPassword"
          label="Repeat Password"
          type={showPassword ? 'text' : 'password'}
          value={repeatPass} 
          onChange={(ev) => setRepeatPass(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleRegistration}>
        Register
      </LoadingButton>
    </>
  );
}
